import { Component } from 'react'
import axios from 'axios'
import { UnexpectedErrorMessage } from './UnexpectedError'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    axios.post('/api/register/error', {
      componentStack: errorInfo?.componentStack,
      error: error.stack,
    })
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      return <UnexpectedErrorMessage />
    }

    // Return children components in case of no error

    return this.props.children
  }
}

export default ErrorBoundary
