import { extendTheme } from '@chakra-ui/react'

export const customTheme = extendTheme({
  styles: {
    global: (props) => ({
      html: {
        margin: 0,
        padding: 0,
        height: '100%',
        overflowX: 'hidden',
        // overflowY: 'scroll',
      },
      body: {
        margin: 0,
        padding: 0,
        height: '100%',
        color: 'gray.600',
      },
    }),
  },
  fonts: {
    body: 'Space Grotesk Variable,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',
    heading:
      'Space Grotesk Variable,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif',
  },
  colors: {
    primary: '#7B5EDB',
    secondary: '#2E32AB',
    brandFull: {
      50: '#7B5EDB',
      100: '#7B5EDB',
      200: '#7B5EDB',
      300: '#7B5EDB',
      400: '#7B5EDB',
      500: '#7B5EDB',
      600: '#7B5EDB',
      700: '#7B5EDB',
      800: '#7B5EDB',
      900: '7B5EDB',
    },
    brand: {
      50: '#f1e9ff',
      100: '#d0bff4',
      200: '#ad97e9',
      300: '#876dde',
      400: '#6f43d4',
      500: '#602abb',
      600: '#522192',
      700: '#401769',
      800: '#2a0d41',
      900: '#12041b',
    },
    primaryVariantDark: '#5FD1D6',
    textPrimary: '#4f566b',
    link: '#6B4AD9',
    lightGrey: '#fafafa',
    bodyBgGray: 'rgba(252,252,252)',
    darkGrey: '#8f9896',
    blackOpacity: '#000000cc',
    whiteOpacity: '#ffffffcc',
  },
  fontSizes: {
    xxs: '0.55rem',
  },
  sizes: {
    container: {
      standard: '1152px',
    },
  },
  components: {
    Input: {
      defaultProps: {
        focusBorderColor: 'primary',
      },
    },
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: 'none',
        },
      },
    },
    Button: {
      variants: {
        primary: {
          background: 'primary',
          color: 'white',
        },
      },
    },
    Tabs: {
      variants: {
        baseStyle: {
          padding: '0',
        },
      },
    },
    TabList: {
      varians: {
        baseStyle: {
          padding: '0',
        },
      },
    },
    Tab: {
      varians: {
        baseStyle: {
          padding: '0',
        },
      },
    },
  },
})
