import { Box, Button, Divider, Text, Link } from '@chakra-ui/react'
import NextLink from 'next/link'

export const UnexpectedErrorMessage = () => {
  return (
    <Box
      minH='100vh'
      display='flex'
      justifyContent='center'
      alignItems='center'
      px={[4, null, 0]}
      textAlign={['center', null, 'initial']}
    >
      <Box>
        <Box
          display='flex'
          flexDir={['column', null, 'row']}
          h={['auto', null, 100]}
          experimental_spaceX={[0, null, 4]}
          mb={8}
          padding='1rem'
        >
          <Text fontSize='5xl' fontWeight='bold'>
            500
          </Text>
          <Divider orientation='vertical' />
          <Box>
            <Text fontSize={['3xl', null, '5xl']} fontWeight='bold'>
              Ocurrio algun error inesperado
            </Text>
            <Text>
              El equipo de Autoinspector ya fue notificado del error y lo
              solucionara lo mas antes posible. Disculpa por las molestias
              ocasionadas.
            </Text>
            <Box mt={4}>
              <NextLink href='/'>
                <Button colorScheme='brand' mr={4}>
                  Inicio
                </Button>
              </NextLink>
              <Button as={Link} href='mailto:support@autoinspector.ai'>
                Soporte
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
